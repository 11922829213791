@import "../../scss/variables.scss";

header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  section {
    background-color: $bgWhite;
    padding-top: 8px;
    padding-bottom: 8px;
    @media only screen and (max-width:$menuBreak) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .content_wrap {
      .header_grid {
        display: grid;
        grid-template-columns: 100px 1fr 150px;
        gap: 16px;
        @media only screen and (max-width: $menuBreak) {
          grid-template-columns: 1fr 1fr;
        }
        a.logo_wrap {
          img {
            width: 80px;
            object-fit: contain;
          }
        }

        nav {
          display: flex;
          justify-content: center;
          align-items: center;
          gap:16px;
          @media only screen and (max-width:1150px) {
            gap: 12px;
          }

          @media only screen and (max-width:$menuBreak) {
            display: none;
          }
          a {
            color: $maroon;
            position: relative;
            white-space: nowrap;
            font-size: 16px;
            line-height: 20px;
            &::after {
              content: "";
              position: absolute;
              bottom: -2px;
              height: 1px;
              width: 0%;
              background-color: $orange;
              left: 50%;
              transform: translateX(-50%);
              transition: all 0.3s ease-in-out;
            }
            &.detect,
            &:hover {
              color: $orange;
              &:after {
                width: 100%;
              }
            }
          }
        }

        .btn_side {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap:8px;
          button.main_btn {
            background-color: $offBlack;
            color: $white;
            border: 1px solid $white;

            @media only screen and (max-width: $tab) {
              display: none;
            }
            &:hover {
              border-style: none;
              background-color: $white;
              color: $offBlack;
            }
          }
          button.menu_btn {
            border-style: none;
            background-color: transparent;

            display: none;
            @media only screen and (max-width: $menuBreak) {
              display: block;
            }

            svg {
              color: $maroon;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
