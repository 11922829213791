@import "../../../scss/variables.scss";

section.home_landing {
  pointer-events:none;
  background-color: $black;
  position: relative;
  overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 96px;
  @media only screen and (max-width:$tab){
    padding-top:80px;
  }
  .video_cover {
    width: 100%;
    height: auto;
    object-fit: contain;
    .video_player {
      margin:-7% 0px;
    }
  }

  h1.big {
    max-width: 1200px;
    width:100%;
    position: absolute;
    bottom:0px;
    left: 50%;
    transform: translate(-50%, -48px);
    @media only screen and (max-width:$tab){
      transform: translate(-50%, -24px);
    }   
  }
  .content_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    p {
      padding: 16px;
      border-radius: 16px;
      border: 1px solid $white;
      color: $white;
      max-width: 500px;
      width: 100%;
    }
    h1 {
      color: #f5801b;
    }
  }
}
