@import "../../../scss/variables.scss";
@import "../../../scss/mixin.scss";

section{
    .content_wrap{
        .bi_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 56px;
            @media only screen and (max-width: $laptop) {
              grid-template-columns: 1fr;
              gap: 40px;
            }
            .img_wrap {
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .text_side {
              .desc {
                p {
                  margin-bottom: 12px;
                  @include font(
                    "Poppins-Regular",
                    18px,
                    24px,
                    400,
                    rgba($black, 0.6)
                  );
                }
                strong {
                  font-weight: bolder;
                  color: $black;
                }
              }
              button{
                margin-top:8px;
                border:1px solid $black;
              }
            }
          }
    }
}