@import "../../scss/variables";
@import "../../scss/mixin";

.offcanvas {
  &.event_detail {
    
    min-width: 600px;
    @media only screen and (max-width: 590px) {
      min-width: 100%;
      width: 100%;
    }
    .offcanvas-header {
      border-bottom: 1px solid rgba($black, 0.5);
      background-color:$skyBgLight;
      .offcanvas-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        width: 100%;
        p {
          font-size: 14px;
          line-height: 18px;

        }
       
      }
      h5 {
        color: $black;
      }
      button.close {
        border-style: none;
        background-color: transparent;
        svg {
          color: $black;
          font-size: 24px;
        }
      }
    }
    .offcanvas-body {
      background-color:$skyBgLight;
  
      
      p.dark{
        &.category{          
          background-color:$offYellow;
          padding:4px 16px;
          border-radius:16px;
          display:inline-block;
          font-size:14px;
          line-height:14px;
          text-transform:capitalize;
        }
      }
      .desc {
        margin-bottom:24px;
        margin-top: 16px;
      p{
        @include font("Poppins-Regular", 18px, 26px, 400, rgba($black, 0.6));
        @media only screen and (max-width:$tab){
            @include font("Poppins-Regular", 16px, 20px, 400, rgba($black, 0.6));
        }
        margin-top:16px;
        strong{
          color:$black;
          font-weight:bolder;
        }
      }
        iframe {
          max-width: 100%;
          width:100%;
          object-fit: contain;
          aspect-ratio:16 / 9 !important;
        }
      }
    }
  }
}
