@import "../../../scss/variables.scss";

section.video_event {
  .content_wrap {
    .sec_title {
      margin: 56px auto;
    }
    h4 {
      color: $black;
      padding: 16px 24px;
      background-color: $sky;
      margin-bottom: 48px;
      border-radius: 16px;
      &.a {
        background-color: $offYellow;
      }
    }
    .event_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      @media only screen and (max-width: $tab) {
        grid-template-columns: 1fr;
      }

      .event_card {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        transition: all 0.3s ease-in-out;
        iframe {
          border-radius: 8px;
          width: 100%;
          aspect-ratio: 16 / 9;
        }
        .img_wrap {
          border-radius: 16px;
          overflow: hidden;
          aspect-ratio:9 / 6;
          background-color:rgba($sky, 0.4);
          display:flex;
          justify-content:center;
          align-items:center;
          img {
            border-radius: 16px;
            max-width: 100%;
            max-height:100%;
            object-fit:contain;
            transition: all 0.3s ease-in-out;
          }
        }
        .info_side {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          gap: 8px;
          .top {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            p.date {
              font-size: 14px;
              line-height: 18px;
            }
            h5 {
              color: $offBlack;
              margin: 8px 0px;
            }
            p.dark {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          button.main_btn {
            background-color: $white;
            padding: 8px 16px;
            margin-top: 8px;
            &:hover {
              background-color: $offYellow;
            }
          }
        }
        &:hover {
          .img_wrap {
            img {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
}
