@import "../../scss/variables.scss";

section.page_landing {
  .content_wrap {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    @media only screen and (max-width: $mobile) {
      min-height: 50vh;
    }
    .title_line {
      position: relative;
    
      h1 {
        color: $white;
        position: relative;
        z-index: 1;
        font-size: 140px;
        line-height: 160px;
        letter-spacing: -4px;
        text-transform: uppercase;
        @media only screen and (max-width: $laptop) {
          font-size: 100px;
          line-height: 140px;
          letter-spacing: 0px;
        }
        @media only screen and (max-width: $tab) {
          font-size: 80px;
          line-height: 90px;
        }
        @media only screen and (max-width: $mobile) {
          font-size: 48px;
          line-height: 56px;
        }
      }
    }
  }
}
