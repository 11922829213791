@import "../../../scss/variables.scss";

section.donation_info {
  background-color: $offBlack;
  .content_wrap {
    h1 {
      color: $white;
      text-align: center;
    }

    p.special {
      text-align: center;
      margin-top: 32px;
      margin-bottom: 56px;
      @media only screen and (max-width:$tab){
        margin-top:24px;
      margin-bottom:16px;
      }
    }
    h4 {
      color: $white;
      transition: all 0.3s ease-in-out;
      &.line {
        text-decoration: underline;
        margin-top:32px;
        cursor: pointer;
        @media only screen and (max-width:$tab){
          margin-top:16px;        
        }
        &:hover {
          color: $yellow;
        }
      }
    }
  }
}
