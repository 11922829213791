@import "../../../scss/variables.scss";
@import "../../../scss/mixin.scss";

section.our_team {
  background-color: $bgLightYellow;
  .content_wrap {
    h1 {
      text-align: center;
      margin-bottom: 56px;
    }
    .bi_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 56px;
      @media only screen and (max-width: $laptop) {
        grid-template-columns: 1fr;
        gap: 40px;
      }
      .img_wrap {
      aspect-ratio:6 / 4;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text_side {
        .desc {
          p {
            margin-bottom: 12px;
            @include font(
              "Poppins-Regular",
              18px,
              24px,
              400,
              rgba($black, 0.6)
            );
          }
          strong {
            font-weight: bolder;
            color: $black;
          }
        }
      }
    }
    .team_grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 24px;
      @media only screen and (max-width: $laptop) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media only screen and (max-width: $tab) {
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }
      @media only screen and (max-width: $mobile) {
        grid-template-columns: 1fr;
        gap: 16px;
      }
      .team_card {
        cursor: pointer;
        .img_wrap {
          margin-bottom: 16px;
          img {
            width: 100%;
            object-fit: contain;
            border-radius: 8px;
          }
        }
        .info {
          h5 {
            color: $offBlack;
          }
          
        }
      }
    }
  }
}
