@import "../../../scss/variables.scss";

section.what_we_do {
  background-color: $white;
  position: relative;

  .content_wrap {
    position: relative;
    .bi_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      @media only screen and (max-width: $laptop) {
        grid-template-columns: 1fr;
        gap: 32px;
      }
      .img_wrap {
        position: relative;
        @media only screen and (max-width: $laptop) {
          order: 1;
        }
        .video_cover{
          position:relative;
          width:100%;
          height:100%;
          object-fit:cover;
          z-index:0;
          display:grid;
          place-items:center;
          position:relative;
          
          .video_player{
            height:100%;
            width:100%;
            object-fit:cover;
            background-color:#000;
          }
        }
       
      }
      .para_side {
        @media only screen and (max-width: $laptop) {
          order: 0;
        }
        p {
          &.box {
            border: 1px solid $black;
            border-radius: 8px;
            padding: 16px;
          }
          &.light {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
