@import "../../../scss/variables.scss";

section.sponser_list {
  .content_wrap {
    h1 {
      text-align: center;
      margin-bottom: 56px;
    }
    .list_grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 16px;
      row-gap: 24px;
      @media only screen and (max-width:$laptop){
        grid-template-columns: 1fr 1fr;
      }
      @media only screen and (max-width:$mobile){
        grid-template-columns: 1fr;
      }
      .sponser_card {
        .img_wraper {
          border-radius: 16px;
          overflow: hidden;
          aspect-ratio: 9 / 6;
          background-color: rgba($sky, 0.4);
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            border-radius: 16px;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            transition: all 0.3s ease-in-out;
          }
        }
        h5{
          color: $black;
          margin: 16px 0px 8px;
        }
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        button.main_btn {
          background-color: $white;
          padding: 8px 16px;
          margin-top: 8px;
          &:hover {
            background-color: $black;
            color: $white;
            svg {
              color: $white;
            }
          }
        }
      }
    }
  }
}
