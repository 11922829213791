@import "../../../scss/variables.scss";

.modal {
  &.preview_screen {
    .modal-body {
      background-color: $offBlack;
      position: relative;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
      button {
        border-style: none;
        background-color: rgba($orange, 0.7);
        color: $white;
        padding: 16px 8px;
        display: grid;
        place-items: center;
        border-radius: 8px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: $orange;
        }
        svg {
          font-size: 20px;
        }
        &.next {
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
        }
        &.prev {
          position: absolute;
          top: 50%;
          left: 24px;
          transform: translateY(-50%);
        }
        &.close {
          position: absolute;
          top: 24px;
          right: 24px;
          padding: 8px;
          border-radius: 50%;
        }
        &:disabled {
          opacity: 0;
        }
      }
      .video_wrap{
        iframe {
          object-fit:contain;
          border-radius: 8px;
          width: 100%;
          aspect-ratio: 16 / 9;
        }
      }
    }
  }
}
