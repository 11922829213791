@import "../../scss/variables.scss";

.error_field {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:8px;
  svg {
    font-size:18px;
    color: $orange;
  }
  p.dark {
    color: $orange;
    font-size:16px;
    line-height:16px;
  }
}
