@import "../../../scss/variables.scss";

section.stream_sec {
  background-color: $white;
  .content_wrap {
    .bi_sec {
      margin-top:24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      padding: 24px;
      border-radius: 16px;
      background-color: $offYellow;
      @media only screen and (max-width: $laptop) {
        grid-template-columns: 1fr;
      }

      .video_wrap {
        iframe {
          border-radius: 8px;
          width: 100%;
          aspect-ratio: 16 / 9;
        }
      }
      .text_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        p.dark {
          margin-top: 16px;
        }
        button.main_btn {
          background-color: transparent;
          border: 1px solid $black;
          margin-top: 24px;
          &:hover {
            border: 1px solid $black;
          }
        }
      }
    }
  }
}
