@import "../../../scss/variables.scss";

section.join_community {
  background-color: $offBlack;
  .content_wrap {
    h2 {
      text-align: center;
      color: $white;
      span {
        &.white {
          color: $white;
        }
        &.light {
          color:rgba($white, 0.15);
        }
      }
    }
    .box {
      padding: 72px;
      background-color: $sky;
      display: grid;
      display: grid;
      grid-template-columns:1fr 182px;
      gap: 24px;
      border-radius: 16px;
      margin-top: 100px;
      @media only screen and (max-width: $tab) {
        grid-template-columns: 1fr;
      }
      img {
        width: 100%;
        max-width: 100px;
        margin-left: auto;
        margin-right: auto;
      }
      h3 {
        align-self:center;
        text-transform:capitalize;
        @media only screen and (max-width: $tab) {
          text-align: center;
        }
      }

      .btn_side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        @media only screen and (max-width: $tab) {
          align-items: center;
        }
      }
    }
  }
}
