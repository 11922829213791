@import "../../../scss/variables.scss";

section.why_we_care{
    background-color:$offBlack;
    .content_wrap{
        h1.sec_title{
            color:$white;
        }
        .bi_grid{
            display:grid;
            grid-template-columns:1.5fr 1fr;
            gap:16px;
            @media only screen and (max-width:$laptop){
                grid-template-columns:1fr;
                gap:24px;                
            }
            .left{
                padding-top:32px;
                @media only screen and (max-width:$laptop){
                    padding-top:0px;
                }
                h4{
                    border-radius:16px;
                    background-color:rgba($white, 0.05);
                    padding:24px;
                    text-transform:unset;
                }
            }
            .img_wrap{
                aspect-ratio:1;
                border-radius:16px;
                position:relative;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                    border-radius:16px;
                   
                }

            }
        }
    }
}