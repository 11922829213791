@import "../../../scss/variables.scss";

section.why_pleadge {
  padding-left: 0px;
  padding-right: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  @media only screen and (max-width: $laptop) {
    grid-template-columns: 1fr;
  }
  .img_wrap {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    min-height:700px;
    .overlay_wrap{
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      background-color:rgba(#000, 0.7);
      z-index: 1;
    }
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: center;
      z-index: 0;
    }
    .text_layer {
      position: absolute;
      width: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index:2;
      @media only screen and (max-width: $tab) {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;

      }
      @media only screen and (max-width: $mobile) {
        padding-left: 16px;
        padding-right: 16px;
      }
      h2 {
        color: $offYellow;
      }
      p.dark {
        color: $white;
        margin: 32px 0px;

        @media only screen and (max-width: $tab) {
          margin: 16px 0px;
        }
      }
      button.main_btn {
        border: 1px solid $white;
        color: $white;
        background-color: transparent;
        svg {
          color: $white;
        }
        &:hover {
          background-color: $white;
          color: $black;
          svg {
            color: $black;
          }
        }
      }
    }
  }
  .text_wrap {
    padding: 80px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media only screen and (max-width: $desktop) {
      padding:60px;
    }
    @media only screen and (max-width: $laptop) {
      padding: 60px 24px 80px;
    }
    @media only screen and (max-width: $mobile) {
      padding: 60px 16px 80px;
    }
    .text_card {
      padding: 16px;
      border-radius: 8px;
      background-color: rgba($sky, 0.2);
      h4 {
        color: $black;
      }
      p.dark {
        margin-top: 16px;
        font-size:18px;
        line-height: 24px;
      }
    }
  }
}
