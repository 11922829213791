$black: #000000;
$white: #ffffff;
$yellow: #ffd231;
$offYellow: #ffe588;
$offBlack: #1d1d1e;
$sky: #b8d4e3;
$darkSky: #6e90a2;
$orange: #c31e27;
$grey: #433336;
$offWhite: #f7f8f0;
$bgOffWhite: #e8ecef;
$bgLightYellow: #fbfcf4;
$bgWhite: #f1f1f2;
$maroon: #3c1212;
$skyBgLight:#b8d4e378;
$lightText:#000000ad;

// Break Points
$bigDesktop:1400px;
$desktop: 1200px;
$laptop: 991px;
$tab: 767px;
$mobile: 480px;
$menuBreak:1100px;
