@import "../../../scss/variables.scss";
@import "../../../scss/mixin.scss";

section.title_sec {
  position: relative;
  background-color: $sky;
  @media only screen and (max-width: $tab) {
    padding: 0px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: $sky;
    height: 48px;
    border-radius: 56px 56px 0px 0px;
    transform: translateY(-48px);
    z-index: 2;
  }
  .content_wrap {
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: $tab) {
      padding: 0px;
    }
    h1.sec_title {
      color: $black;
      position: relative;
      display: inline-block;
      @media only screen and (max-width: $tab) {
        margin-bottom: 0px;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translate(-50%);
        height: 2px;
        width: 100%;
        background-color: $black;
      }
    }
  }
}

section.commit_sec {
  position: sticky;
  background-color: $sky;
  @media only screen and (max-width: $tab) {
    position: relative;
    top: 0px !important;
    padding-top: 48px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: $sky;
    height: 48px;
    border-radius: 56px 56px 0px 0px;
    transform: translateY(-48px);
    z-index: 2;
    @media only screen and (max-width: $tab) {
      display: none;
    }
  }

  &:nth-child(2) {
    background-color: $sky;
    &::after {
      background-color: $sky;
    }
  }
  &:nth-child(3) {
    background-color: #fac898;
    &::after {
      background-color: #fac898;
    }
  }
  &:nth-child(4) {
    background-color: #b6d0e2;
    &::after {
      background-color: #b6d0e2;
    }
  }
  &:nth-child(5) {
    background-color: $offYellow;
    &::after {
      background-color: $offYellow;
    }
  }
  &:nth-child(6) {
    background-color: $yellow;
    &::after {
      background-color: $yellow;
    }
  }
  &:nth-child(7) {
    background-color: $yellow;
    &::after {
      background-color: $yellow;
    }
  }

  .content_wrap {
    .title_line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      h1 {
        color: $white;
        font-size: 100px;
        line-height: 110px;
        @media only screen and (max-width: $desktop) {
          font-size: 92px;
          line-height: 100px;
        }
        @media only screen and (max-width: $laptop) {
          font-size: 92px;
          line-height: 100px;
        }
        @media only screen and (max-width: $tab) {
          font-size: 60px;
          line-height: 68px;
        }
        @media only screen and (max-width: $mobile) {
          font-size: 48px;
          line-height: 56px;
        }
      }
      h4 {
        color: $offBlack;
      }
    }
    .bi_sec {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 56px;
      @media only screen and (max-width: $laptop) {
        gap: 32px;
      }
      @media only screen and (max-width: $tab) {
        grid-template-columns: 1fr;
        gap: 16px;
      }
      .img_wrap {
        img {
          width: 100%;
          min-height:400px;
          object-fit: cover;
          @media only screen and (max-width:$tab){
            min-height:auto;
          }
        }
      }
      .text_wrap {
        .dark {
          p {
            margin-bottom:12px;
            @include font("Poppins-Regular", 22px, 26px, 400, rgba($black, 0.6));
          }
          strong {
            font-weight: bolder;
            color: $black;
          }
        }
      }
    }
  }
}

section.cta {
  background-color: $yellow;
  position: relative;
  z-index: 2;
  .content_wrap {
    .box {
      padding: 72px;
      background-color: $black;
      display: grid;
      display: grid;
      grid-template-columns: 1fr 182px;
      gap: 24px;
      border-radius: 16px;
      @media only screen and (max-width: $tab) {
        grid-template-columns: 1fr;
      }
      img {
        width: 100%;
        max-width: 100px;
        margin-left: auto;
        margin-right: auto;
      }
      h3 {
        text-transform: capitalize;
        color: $yellow;
        align-self: center;
        @media only screen and (max-width: $tab) {
          text-align: center;
        }
      }

      .btn_side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: $tab) {
          align-items: center;
        }
        button.main_btn {
          border: 1px solid $white;
          color: $white;
          background-color: transparent;
          svg {
            color: $white;
          }
        }
      }
    }
  }
}
