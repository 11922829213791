@import "../../../scss/variables.scss";

section.our_mission {
  background-color: $sky;

  .content_wrap {
    .mission_sec {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .mission_card {
        padding: 24px;
        background-color: $darkSky;
        border-radius: 16px;
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 100px;
        transition: all 0.3s ease-in-out;

        .img_wrap {
          position: relative;

          .num {
            position: absolute;
            top: 50%;
            transform: translate(50%, -50%);
            right: 0px;
            padding: 16px;
            background-color: $darkSky;
            border-radius: 8px;
            opacity: 0;
            display: grid;
            place-items: center;
            width: 100px;
            transition: all 0.3s ease-in-out;
            h1 {
              color: $white;
            }
            transition: all 0.3s ease-in-out;
          }
          img {
            width: 100%;
            object-fit: contain;
            border-radius: 8px;
          }
        }
        h4 {
          align-self: center;
        }
        @media only screen and (max-width: $laptop) {
          grid-template-columns: 1fr;
          gap: 24px;
          .img_wrap {
            order: 1;
            .num {
              opacity: 1;
              position: absolute;
              top: 0px;
              right: 0px;
              transform: translate(0px, -50%);
              height: 60px;
              width: 60px;
              background-color: $orange;

              h1 {
                font-size: 32px;
                line-height: 32px;
              }
            }
          }
        }
        &:hover {
          background-color: $orange;
          .img_wrap {
            .num {
              opacity: 1;
            }
          }
        }
        &:nth-child(2n) {
          grid-template-columns: 1fr 300px;
          gap: 100px;
          .img_wrap {
            order: 1;
            .num {
              left: 0px;
              transform: translate(-50%, -50%);
            }
          }
          @media only screen and (max-width: $laptop) {
            grid-template-columns: 1fr;
            gap: 24px;
            .img_wrap {
              .num {
                opacity: 1;
                position: absolute;
                top: 0px;
                left: 100%;
                transform: translate(-100%, -50%);
                height: 60px;
                width: 60px;
                background-color: $orange;

                h1 {
                  font-size: 32px;
                  line-height: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
}
