@import "../../scss/variables.scss";

footer {
  background-color: $offBlack;
  padding-top: 32px;
  padding-bottom: 56px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: $offBlack;
    height: 48px;
    border-radius: 56px 56px 0px 0px;
    transform: translateY(-48px);
  }
  section.footer_sec {
    .content_wrap {
      .bi_sec {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
        @media only screen and (max-width: $laptop) {
          grid-template-columns: 1fr;
        }
        .img_wrap {
          position: relative;
          align-self: flex-start;
          overflow: hidden;
          img.bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 16px;
            z-index: 0;
          }
          .layer_wrap {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;

            h3 {
              color: #f5801b;
              text-align: center;
              word-wrap: wrap;
              white-space: wrap;
            }
          }
        }
        .info_side {
          .link_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 16px;
            @media only screen and (max-width: $mobile) {
              grid-template-columns: 1fr 1fr;
              gap: 8px;
            }
            .link_col {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              gap: 8px;
              a {
                color: rgba($white, 0.5);
                &:hover,
                &.detect {
                  color: $white;
                }
              }
            }
          }
          .director_card {
            margin-top: 32px;
            padding: 32px;
            border: 1px solid #f5801b;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            @media only screen and (max-width: $mobile) {
              padding: 16px;
              gap: 8px;
            }
            h5 {
              color: #f5801b;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              @media only screen and (max-width: $mobile) {
                font-size: 18px;
                line-height: 20px;
              }
            }
            p.dark {
              text-align: center;
              color: $white;
            }
            img {
              width: 56px;
              height: 56px;
              border-radius: 50%;
            }
          }
          .social_line {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 16px;
            gap: 8px;
            @media only screen and (max-width: $mobile) {
              justify-content: center;
            }
            a {
              height: 48px;
              width: 48px;
              background-color: $white;
              border-radius: 50%;
              transition: all 0.3s ease-in-out;
              display: grid;
              place-items: center;
              svg {
                font-size: 20px;
                color: $offBlack;
              }
              &:hover {
                background-color: #f5801b;
                transition: all 0.3s ease-in-out;
              }
            }
          }
          p.rights {
            color: $white;
            text-align: right;
            margin-top: 16px;
            font-size: 16px;
            line-height: 20px;
            @media only screen and (max-width: $mobile) {
              text-align: center;
            }
            a {
              font-size: 16px;
              line-height: 20px;
              transition: all 0.3s ease-in-out;
              &:hover {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
