@import "../../scss/variables";
@import "../../scss/mixin";

.offcanvas {
  &.event_detail {
    
    min-width: 600px;
    @media only screen and (max-width: 590px) {
      min-width: 100%;
      width: 100%;
    }
    .offcanvas-header {
      border-bottom: 1px solid rgba($black, 0.5);
      background-color:$skyBgLight;
      .offcanvas-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        width: 100%;
            
      }
      h5 {
        color: $black;
      }
      button.close {
        border-style: none;
        background-color: transparent;
        svg {
          color: $black;
          font-size: 24px;
        }
      }
    }
    .offcanvas-body {
      background-color:$skyBgLight;
      .img_wraper {
        margin-bottom: 16px;        
        img {
          max-width:200px;
          aspect-ration:3 / 2;        
          object-fit: contain;
          border-radius: 8px;
        }
      }
      
  
    }
  }
}
