@import "../../scss/variables.scss";

.offcanvas {
  &.menu_drower {
    transition: all 0.4s ease-in-out;
    z-index: 9999;
    background-color:#F1F1F2;
    height: 100vh;
    .offcanvas-header {
      border-bottom: 1px solid rgba(#3c1212, 0.2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      padding:0px 24px;
      @media only screen and (max-width: $mobile) {
        padding-left: 16px;
        padding-right: 16px;
      }
      img {
        width:60px;
        object-fit: contain;
      }
      button.close_btn {
        border-style: none;
        background-color: transparent;
        display: grid;
        place-items: center;
        svg {
          font-size: 24px;
          color:#3c1212;
        }
      }
    }
    .offcanvas-body {
      padding-left: 24px;
      padding-right: 24px;
      @media only screen and (max-width: $mobile) {
        padding-left: 16px;
        padding-right: 16px;
      }
      nav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        a {
          color:#3c1212;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom:-2px;
            height: 1px;
            width: 0%;
            background-color:#3c1212;
            left: 50%;
            transform: translateX(-50%);
            transition: all 0.3s ease-in-out;
          }
          &.detect,
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
        button.contact_btn {
          background-color: $offBlack;
          color: $white;
          border:1px solid $offBlack;
      
          &:hover {
            background-color: $white;
            color: $offBlack;
            border:1px solid $offBlack;
          }
        }
      }
    }
  }
}
