@import "../../../scss/variables.scss";

section.who_we_are {
  background-color: $offBlack;
    .content_wrap {
    h1{
          color:$white;
          text-align:center;
          margin-bottom:56px;
    }
    .bi_sec {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      @media only screen and (max-width: $laptop) {
        grid-template-columns: 1fr;
        gap: 48px;
      }
      .img_wrap {
        position: relative;
        display:grid;
        place-items:center;
        @media only screen and (max-width: $laptop) {
          order: 1;
        }
        img{
          width:100%;
          object-fit:contain;
        }
        .video_cover{
          position:relative;
          width:100%;
          height:100%;
          object-fit:cover;
          z-index:0;
          display:grid;
          place-items:center;
          position:relative;
          
          .video_player{
            height:100%;
            width:100%;
            object-fit:cover;
            background-color:#000;
          }
        }
       
      }
      .text_wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
        @media only screen and (max-width: $laptop) {
          gap: 16px;
        }
       h4{
            color:$white;
        }
      p{
        color:$white;
      }
      }
    }
  }
}
