@import "../../../scss/variables.scss";

section.solution_sec {
  background-color: $offBlack;
  .content_wrap {
    h1 {
      color: $white;
      text-align: center;
    }

    p.special {
      text-align: center;
      margin-top:32px;
      margin-bottom:56px;
    }
    h4{
      color:$white;

      &.line{
        text-decoration:underline;
      }
    }

    .solution_list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .solution_card {
        display: grid;
        grid-template-columns: 150px 1fr;
        gap: 32px;
        padding: 32px;
        border-radius: 16px;
        @media only screen and (max-width: $laptop) {
          gap: 24px;
          grid-template-columns: 100px 1fr;
        }
        @media only screen and (max-width: $tab) {
          grid-template-columns: 60px 1fr;
          padding: 24px;
          gap: 16px;
        }
        @media only screen and (max-width: $mobile) {
          grid-template-columns: 48px 1fr;
          padding: 16px;
        }
        .num {
          display: flex;
          justify-content: center;
        }
        .text {
          h4 {
            color: $offBlack;
            margin-bottom: 8px;
            @media only screen and (max-width: $tab) {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
