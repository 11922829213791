@import "../../../scss/variables.scss";

section.form_sec {
  background: $sky;
  position: relative;
  overflow:hidden;

  .content_wrap {
    max-width: 600px;
    .success_card{
      padding:48px;
      background-color:$offBlack;
      border-radius:16px;
      @media only screen and (max-width:$tab){
        padding:32px;
      }
      @media only screen and (max-width:$mobile){
        padding:16px;
      }
      p.dark{
        color:$white;
        padding:16px 24px;
        border:1px solid $white;
        border-radius:32px;
        text-align:center;
      }
    }
    form {
      background-color: $offBlack;
      padding: 48px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      width: 100%;
      @media only screen and (max-width:$tab){
        padding:24px;
      }
      .field {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        label {
          margin-bottom: 8px;
          letter-spacing:1px;
        }
        input,
        textarea {
          background-color: transparent;
          width: 100%;
          border-style: none;
          border-bottom: 1px solid $white;
          color: $white;
          padding-bottom: 8px;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }
      button.main_btn{
        background-color:$offYellow;
        width:100%;
      }
    }
  }
}
