@import "../../scss/variables.scss";

section.loader {
  background-color: $bgWhite;
  .content_wrap {
    min-height: Calc(100vh + 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      max-width: 150px;
      object-fit: contain;
    }
  }
}
