@import "../../scss/variables";
@import "../../scss/mixin";

.offcanvas {
  &.team_detail {
    min-width: 800px;
    @media only screen and (max-width: 790px) {
      min-width: 100%;
      width: 100%;
    }
    .offcanvas-header {
      border-bottom: 1px solid rgba($black, 0.5);
      position: relative;
      background-color: $skyBgLight;
      .offcanvas-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        width: 100%;
      }
      h5 {
        color: $black;
      }
      button.close {
        border-style: none;
        background-color: transparent;

        svg {
          color: $black;
          font-size: 24px;
        }
      }
    }
    .offcanvas-body {
      padding-bottom: 48px;
      background-color: $skyBgLight;

      .desc {
        margin-top: 16px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-top: 16px;
        }
        p {
          @include font("Poppins-Regular", 18px, 26px, 400, rgba($black, 0.6));
          @media only screen and (max-width: $tab) {
            @include font(
              "Poppins-Regular",
              16px,
              20px,
              400,
              rgba($black, 0.6)
            );
          }
          margin-top: 16px;
          strong {
            font-weight: bolder;
            color: $black;
          }
        }
        iframe {
          max-width: 100%;
          width: 100%;
          object-fit: contain;
          aspect-ratio: 16 / 9 !important;
        }
      }
    }
  }
}
