@import "../../scss/variables";
@import "../../scss/mixin";

.offcanvas {
  &.founder_detail {
    min-width: 1000px;
    @media only screen and (max-width: 1000px) {
      min-width: 100%;
      width: 100%;
    }
    .offcanvas-header {
      border-bottom: 1px solid rgba($black, 0.5);
      position: relative;
      .offcanvas-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        width: 100%;
      }
      button.close {
        border-style: none;
        background-color: transparent;

        svg {
          color: $black;
          font-size: 24px;
        }
      }
      h4 {
        color: $black;
      }
    }
    .offcanvas-body {
      .accordion {
        .accordion-item {
          margin-bottom:1px;
          border-radius: 4px;
          overflow: hidden;
          .accordion-header {
            button {
              background-color: $sky;
              border-style: none;

              h5 {
                color: $black;
              }
              &:focus {
                box-shadow: none;
                outline: none;
              }
            }
          }
          .accordion-collapse {
            .accordion-body {
              border: 1px solid $sky;
              background-color: rgba($sky, 0.3);
              .desc {
                p {
                  margin-bottom: 8px;
                  @include font("Poppins-Regular", 16px, 20px, 400, rgba($black, 0.6));
                  strong{
                    font-weight:bolder;
                    color:$black;
                  }
                }
                ul {
                  li {
                    @include font("Poppins-Regular", 16px, 20px, 400, rgba($black, 0.6));
                    strong{
                      font-weight:bolder;
                      color:$black;
                    }
                  }
                }
                a{
                  @include font("Poppins-Regular", 14px, 18px, 400, $black);                  
                  font-weight:bolder;
                  transition:all 0.3s ease-in-out;
                  &:hover{
                    color:$orange;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
