@import "../../scss/variables";

section.error_page {

    background-color:$white;
    .content_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        max-width: 400px;
        width: 100%;
        gap: 16px;

        img {
            width: 200px;

            @media only screen and (max-width:$tab) {
                width: 150px;
            }
        }

        h2 {
            color: $offBlack;
            text-align: center;
        }

        p {

            color:$offBlack;
            text-align: center;

        }

        button {
            background-color:$offBlack;
            color:$white;
        }
    }
}