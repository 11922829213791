@import "./mixin.scss";
@import "./variables";

// 400
@font-face {
  font-family: "FFGoodProNarr-Regular";
  src: url("../assets/fonts/FFGoodProNarr-Regular.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

// 500
@font-face {
  font-family: "FFGoodProNarr-Medium";
  src: url("../assets/fonts/FFGoodProNarr-Medium.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

// 700
@font-face {
  font-family: "FFGoodProNarr-Bold";
  src: url("../assets/fonts/FFGoodProNarr-Bold.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

// ***************** Poppins**************
// 300
@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins-Light.woff") format("woff");
  font-style: normal;
  font-display: swap;
}
// 400
@font-face {
  font-family: "Poppins-Regular";
  src: url("../assets/fonts/Poppins-Regular.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

h1 {

  @include font("FFGoodProNarr-Bold", 72px, 80px, 500, $black);
  &.big {
    @include font("FFGoodProNarr-Regular", 140px, 140px, 600, $yellow);
    letter-spacing: 0px;   
    text-transform:uppercase; 
  }
}

h2 {

  @include font("FFGoodProNarr-Bold", 42px, 54px, 700, $black);
}

h3 {

  @include font("FFGoodProNarr-Bold", 56px, 72px, 700, $black);
}

h4 {

  @include font("FFGoodProNarr-Bold", 32px, 40px, 700, $white);
}
h5 {

  @include font("FFGoodProNarr-Bold", 24px, 28px, 700, $white);
}
h6 {

  @include font("FFGoodProNarr-Bold", 18px, 22px, 700, $white);
}

button.underlined{
  @include font("Poppins-Regular", 16px, 20px, 400, $orange);
}

p {
  &.light {
    @include font("Poppins-Light", 18px, 26px, 300, black);
  }
  &.dark {
    @include font("Poppins-Regular", 18px, 26px, 400, $black);
  }
  &.special{
    @include font("FFGoodProNarr-Regular", 20px, 26px, 400, $white);
  }
  &.alert_text{
    @include font("Poppins-Regular", 18px, 26px, 400, $maroon);
  }
  strong{
    font-weight:bolder;
    color:$black;
  }
}
input,
textarea {
  @include font("Poppins-Regular", 18px, 26px, 400, $white);
  &::placeholder {
    @include font("Poppins-Regular", 18px, 26px, 400, rgba($white, 0.5));
  }

}
label {

  @include font("FFGoodProNarr-Regular", 16px, 20px, 400, $white);
}
a {
  text-decoration: none;
  @include font("Poppins-Regular", 18px, 26px, 400, $white);
}
@media only screen and (max-width: $desktop) {
  h1 {
    @include font("FFGoodProNarr-Bold", 60px, 60px, 500, $black);
    &.big {
      @include font("FFGoodProNarr-Regular", 80px, 80px, 600, $yellow);
    }
  }

  h2 {
    @include font("FFGoodProNarr-Bold", 32px, 40px, 700, $black);
  }
  h3 {
    @include font("FFGoodProNarr-Bold", 48px, 60px, 700, $black);
  }
}

@media only screen and (max-width: $laptop) {
  h1 {
    @include font("FFGoodProNarr-Bold", 48px, 48px, 500, $black);
    &.big {
      @include font("FFGoodProNarr-Regular", 60px, 60px, 600, $yellow);
    }
  }
  h3 {
    @include font("FFGoodProNarr-Bold", 32px, 40px, 700, $black);
  }
  h4 {
    @include font("FFGoodProNarr-Bold", 28px, 34px, 700, $white);
  }
  h5 {
  
    @include font("FFGoodProNarr-Bold", 22px, 26px, 700, $white);
  }
}
@media only screen and (max-width: $tab) {
  p {
    &.light {
      @include font("Poppins-Light", 16px, 20px, 300, $black);
    }
    &.dark {
      @include font("Poppins-Regular", 16px, 20px, 400, $black);
    }
  }
  h1 {
    @include font("FFGoodProNarr-Bold", 48px, 48px, 500, $black);
    &.big {
      @include font("FFGoodProNarr-Regular", 48px, 48px, 600, $yellow);
      
    }
  }

  h2 {
    @include font("FFGoodProNarr-Bold", 24px, 32px, 700, $black);
  }
  h3 {
    @include font("FFGoodProNarr-Bold", 28px, 36px, 700, $black);
  }
}
@media only screen and (max-width: $mobile) {
  h1 {
    @include font("FFGoodProNarr-Bold", 48px, 48px, 500, $black);
    &.big {
      @include font("FFGoodProNarr-Regular", 32px, 32px, 600, $yellow);
    }
  }
  h2 {
  
    @include font("FFGoodProNarr-Bold", 22px, 28px, 700, $black);
  }
  h3 {
    @include font("FFGoodProNarr-Bold", 24px, 30px, 700, $black);
  }
  h4 {
    @include font("FFGoodProNarr-Bold", 20px, 24px, 700, $white);
  }
  a {
    text-decoration: none;
    @include font("Poppins-Regular", 16px, 20px, 400, $white);
  }
}
