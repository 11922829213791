@import "../../../scss/variables.scss";
@import "../../../scss/mixin.scss";

section.our_founder {
  background-color: $bgOffWhite;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: $bgOffWhite;
    height: 48px;
    border-radius: 56px 56px 0px 0px;
    transform: translateY(-48px);
    z-index: 2;
  }
  .content_wrap {
    .bi_grid {
      display: grid;
      grid-template-columns: 1fr 1.2fr;
      gap: 24px;
      @media only screen and (max-width: $laptop) {
        grid-template-columns: 1fr;
        gap: 48px;
      }
      .img_wrap {
        position: relative;
        img.founder_profile {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 16px;
        }
      }
      .founder_info {
        h1{
          color: $orange;
        }
        h5 {
          color: $offBlack;
        }
        .des{
          p{
            @include font("Poppins-Regular", 18px, 26px, 400, rgba($black, 0.6));
            @media only screen and (max-width: $tab) {
              @include font("Poppins-Regular", 16px, 20px, 400, rgba($black, 0.6));
            }
            strong{
              font-weight:bolder;
              color:$black;
            }
          }

        }

        p {
          margin-top: 16px;
          &.box {
            padding: 16px;
            background-color: rgba($sky, 0.5);
            border-radius: 8px;
            font-weight: bold;
          }
      
        }
        button {
          border: 1px solid $offBlack;
          margin-top: 24px;
          background-color: $white;
          &:hover {
            background-color: $offBlack;
            color: $white;
            svg {
              color: $white;
            }
          }
        }
      }
    }
  }
}
