@import "../../../scss/variables.scss";

section.image_list {
  background-color: $white;
  .content_wrap {
    .center_title {
      h4 {
        text-align: center;
        color: $black;
        margin: 56px 0px;
      }
    }

    .categories {
      margin: 56px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap:wrap;
      gap:8px;
      button.main_btn {
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid rgba($black, 0.2);
        background-color: $white;
        transition: all 0.3s ease-in-out;
        &.active,
        &:hover {
          background-color: $sky;
        }
      }
    }

    .img_grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      @media only screen and (max-width: $laptop) {
        grid-template-columns: 1fr 1fr;
      }
      @media only screen and (max-width: $tab) {
        grid-template-columns: 1fr;
      }
      .img_wrap {
        border-radius: 16px;
        aspect-ratio:6 / 4;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-position: center;
        }
        iframe {
          border-radius: 8px;
          width: 100%;
          aspect-ratio: 6 / 4;
          pointer-events: none;
        }
      }
    }
  }
}
